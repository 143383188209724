import {
  CommonNode,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, Document } from "@contentful/rich-text-types"
import { ReactNode } from "react"
import {
  As,
  Box,
  Heading,
  List,
  ListItem,
  Text,
  SystemStyleObject,
} from "@chakra-ui/react"

function renderText(
  _node: CommonNode,
  children: ReactNode,
  customStyles?: SystemStyleObject
) {
  return (
    <Text
      sx={{
        a: {
          borderBottomColor: "transparent",
          transition: "border 0.2s ease-out",
        },
        "a:hover": { borderBottom: "1px solid", borderBottomColor: "orange" },
        ":not(:last-child)": { mb: 4 },
        ...customStyles,
      }}
    >
      {children}
    </Text>
  )
}

function renderList(
  _node: CommonNode,
  children: ReactNode,
  customStyles?: SystemStyleObject
) {
  return (
    <List
      sx={{
        maxW: "600px",
        "> li:last-of-type": { border: "none" },
        ...customStyles,
      }}
      mb="4"
    >
      {children}
    </List>
  )
}

function renderListItem(
  _node: CommonNode,
  children: ReactNode,
  customStyles?: SystemStyleObject
) {
  return (
    <ListItem
      py={2}
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      sx={customStyles}
    >
      {children}
    </ListItem>
  )
}

function renderHeading(
  _node: CommonNode,
  children: ReactNode,
  level: number,
  customStyles?: SystemStyleObject
) {
  return (
    <Heading
      as={`h${level}` as As}
      variant={`subHeading${level}`}
      mb={2}
      sx={customStyles}
    >
      {children}
    </Heading>
  )
}

const RichText = ({
  doc,
  sx,
}: {
  doc: Document | undefined | null
  sx?: SystemStyleObject
}): JSX.Element | null => {
  if (!doc) return null

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: CommonNode, children: ReactNode) =>
        renderText(node, children, sx),
      [BLOCKS.UL_LIST]: (node: CommonNode, children: ReactNode) =>
        renderList(node, children, sx),
      [BLOCKS.LIST_ITEM]: (node: CommonNode, children: ReactNode) =>
        renderListItem(node, children, sx),
      [BLOCKS.HEADING_2]: (node: CommonNode, children: ReactNode) =>
        renderHeading(node, children, 2, sx),
      [BLOCKS.HEADING_3]: (node: CommonNode, children: ReactNode) =>
        renderHeading(node, children, 3, sx),
      [BLOCKS.HEADING_4]: (node: CommonNode, children: ReactNode) =>
        renderHeading(node, children, 4, sx),
      [BLOCKS.HEADING_5]: (node: CommonNode, children: ReactNode) =>
        renderText(node, children, sx),
      [BLOCKS.HEADING_6]: (node: CommonNode, children: ReactNode) =>
        renderText(node, children, sx),
    },
  }

  return <Box sx={sx}>{documentToReactComponents(doc, options)}</Box>
}

export default RichText
