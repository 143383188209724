import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Text,
  SystemStyleObject,
} from "@chakra-ui/react"
import { eventStatus } from "@typings/index"
import { format, parseISO } from "date-fns"
import { useTranslations } from "use-intl"
import { getEventStatus, isSameDay } from "@utils/helpers"
import LiveBadge from "./LiveBadge"

interface StatusProps extends BoxProps {
  showStartTime: string
  showEndTime: string
  sx?: SystemStyleObject
  showLabel?: boolean
}

type ShowTimeTypes = {
  start: string
  end: string
}

const ShowStatus = (props: StatusProps): JSX.Element => {
  const { showStartTime, showEndTime, sx, showLabel = true, ...rest } = props
  const [showTimes, setShowTimes] = useState<ShowTimeTypes>()
  const router = useRouter()
  const { locale } = router

  const t = useTranslations("show")
  const isoShowStartTime = parseISO(showStartTime)
  const isoShowEndTime = parseISO(showEndTime)

  const singleDayEvent = isSameDay(isoShowStartTime, isoShowEndTime)
  const status = getEventStatus(isoShowStartTime, isoShowEndTime)

  const formattedStartDate = isoShowStartTime.toLocaleDateString(locale || "fi")
  const formattedEndDate = isoShowEndTime.toLocaleDateString(locale || "fi")

  useEffect(() => {
    setShowTimes({
      start: format(isoShowStartTime, "HH.mm"),
      end: format(isoShowEndTime, "HH.mm"),
    })
  }, []) // eslint-disable-line

  switch (status) {
    case eventStatus.live:
      return (
        <Box sx={sx} {...rest}>
          <LiveBadge size="large" sx={{ pl: 0 }} />
        </Box>
      )
    case eventStatus.starting:
    case eventStatus.future:
      return (
        <Box sx={sx} {...rest}>
          <Flex alignItems="baseline">
            {showLabel && (
              <Heading
                variant="subHeading1"
                fontSize={["md", null, "lg"]}
                lineHeight="none"
                mr={2}
                mb={"-2px"}
                as="h3"
              >
                {t("showStatus.upcoming")}
              </Heading>
            )}
            {singleDayEvent && showTimes ? (
              <>
                <Text variant="byline" mr={1} fontSize={"lg"}>
                  {formattedStartDate}
                </Text>
                <Text variant="byline" mr={1} fontSize={"lg"}>
                  {showTimes.start}-{showTimes.end}
                </Text>
              </>
            ) : (
              <Text variant="byline" mr={1} fontSize={"lg"}>
                {formattedStartDate}-{formattedEndDate}
              </Text>
            )}
          </Flex>
        </Box>
      )
    case eventStatus.past:
    default:
      return (
        <Box sx={sx} {...rest}>
          <Text variant="byline" mr={1} fontSize={"lg"}>
            {t("showStatus.shown")}{" "}
            {isoShowStartTime.toLocaleDateString(locale || "fi")}
          </Text>
        </Box>
      )
  }
}

export default ShowStatus
