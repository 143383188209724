import { ReactNode } from "react"
import { Badge, keyframes, SystemStyleObject } from "@chakra-ui/react"
import { useTranslations } from "use-intl"

const blink = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0.15 }
  100% { opacity: 1 }
`

type BadgeProps = {
  sx?: SystemStyleObject
  children?: ReactNode
  size?: "small" | "large"
}

const LiveBadge = (props: BadgeProps): JSX.Element => {
  const { sx, children, size = "small" } = props
  const t = useTranslations("show")

  return (
    <Badge
      variant={size === "small" ? "subHeading2" : "subheading1"}
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: [14, null, 16, 18],
        top: 3,
        right: 3,
        color: "orange",
        mr: 4,
        "::after": {
          ml: [1, 2],
          content: '""',
          width: ["6px", null, "8px"],
          height: ["6px", null, "8px"],
          backgroundColor: "orange",
          borderRadius: "full",
          animation: `${blink} 1.75s ease-in-out infinite`,
        },
        ...sx,
      }}
    >
      {children ?? t("liveBadge.liveLabel")}
    </Badge>
  )
}

export default LiveBadge
