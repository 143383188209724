import { Badge, Box } from "@chakra-ui/layout"
import { SystemStyleObject } from "@chakra-ui/styled-system"
import { useTranslations } from "use-intl"
import CheckIcon from "@components/Icons/Check"

const TicketBadge = ({ sx }: { sx?: SystemStyleObject }): JSX.Element => {
  const t = useTranslations("ticketBadge")

  return (
    <Badge
      pl="4"
      pr="3"
      py="1"
      color="white"
      background="transparent"
      border="2px solid"
      borderColor="gray.100"
      borderRadius="20px"
      lineHeight="1.5"
      sx={sx}
    >
      <Box display="flex" alignItems="center">
        {t("ticketPurchased")} <CheckIcon sx={{ ml: 2, color: "green.100" }} />
      </Box>
    </Badge>
  )
}

export default TicketBadge
