import { definitions } from "@typings/supabase"
import { isPast } from "date-fns"
import * as R from "ramda"
import { log } from "@utils/logging"
import { PriceData } from "@utils/pricing"

type DiscountCode = definitions["discount_codes"]

enum DiscountType {
  percentage = "percentage",
  currency = "currency",
}

/**
 * Checks if a given discount is valid. Only a valid discount should be applied.
 */
const isValidDiscount = (
  discount: DiscountCode,
  productId: string
): boolean => {
  const { id, enabled, expires_at, amount_used, amount, allowed_products } =
    discount

  // Is the discount code enabled? Undefined is considered disabled.
  // Discount codes should be explicitly set enabled if they're in use.
  if (R.isNil(enabled) || enabled === false) {
    log(id, "Someone tried to use a disabled discount code")
    return false
  }

  // Has the discount expired?
  if (isPast(new Date(expires_at))) {
    log(id, "Someone tried to use an expired discount code")
    return false
  }

  // Is the discount usage quota full?
  // If amount and amount_used are set it means at least one
  // code has been used and we'll need to check if there's
  // codes still left.
  if (!R.isNil(amount) && !R.isNil(amount_used) && amount_used >= amount) {
    log(id, "Someone tried to use a discount code with full quota")
    return false
  }

  // Is the discount code limited to specific product IDs
  // If so, does it include the product being purchased
  if (!R.isNil(allowed_products) && !allowed_products.includes(productId)) {
    return false
  }

  return true
}

/**
 * Applies a discount and returns the price data with the discount applied.
 * You probably shouldn't use this method directly and call getPrice() instead.
 */
const applyDiscount = (price: PriceData, discount: DiscountCode): PriceData => {
  const { discount_type: discountType } = discount

  // Make a copy of the price for modification.
  let discountedPrice = R.clone(price)

  // Modify the price based on the discount.
  switch (discountType) {
    case DiscountType.percentage:
      discountedPrice = _applyPercentageDiscount(discountedPrice, discount)
      break
    case DiscountType.currency:
      discountedPrice = _applyCurrencyDiscount(discountedPrice, discount)
      break
    default:
      log(
        discount.id,
        "Unknown DiscountType encountered when applying a discount"
      )
      break
  }

  return discountedPrice
}

/**
 * JavaScript's Match.round() is weird, thus this hack.
 * Found from: https://www.jacklmoore.com/notes/rounding-in-javascript/
 * Only use with positive numbers.
 */
const round = (positiveValue: number, decimals: number): number => {
  // eslint-disable-next-line
  // @ts-ignore
  return Number(Math.round(positiveValue + "e" + decimals) + "e-" + decimals)
}

/**
 * Internal method to apply a percentage based discount to a price.
 * You probably shouldn't be using this directly.
 * Reference: https://support.laskupiste.fi/support/solutions/articles/47000338102-miten-eurom%C3%A4%C3%A4r%C3%A4iset-ja-prosenttikohtaiset-alennukset-lasketaan-
 */
const _applyPercentageDiscount = (
  price: PriceData,
  discount: DiscountCode
): PriceData => {
  const { id, discount_amount: discountInPercent } = discount
  const { price: withTaxPriceInCents, tax: taxPercent } = price

  // Full or more than full discount
  if (discountInPercent >= 100) {
    return {
      ...price,
      pretaxPrice: 0,
      price: 0,
      discount: withTaxPriceInCents,
    }
  }

  // Zero or negative discount
  if (discountInPercent <= 0) {
    log(id, "A zero or negative discount encountered")
    return price
  }

  // Partial discounts, discount is given from the price including the tax
  const newPriceInCents = round(
    withTaxPriceInCents * (1 - discountInPercent / 100),
    0
  )
  const newPretaxPriceInCents = round(
    newPriceInCents / (1 + taxPercent / 100),
    0
  )

  return {
    ...price,
    pretaxPrice: newPretaxPriceInCents,
    price: newPriceInCents,
    discount: withTaxPriceInCents - newPriceInCents,
  }
}

/**
 * Internal method to apply a currency based discount to a price.
 * You probably shouldn't be using this directly.
 * Reference: https://support.laskupiste.fi/support/solutions/articles/47000338102-miten-eurom%C3%A4%C3%A4r%C3%A4iset-ja-prosenttikohtaiset-alennukset-lasketaan-
 */
const _applyCurrencyDiscount = (
  price: PriceData,
  discount: DiscountCode
): PriceData => {
  const { id, discount_amount: discountInCents } = discount
  const { price: withTaxPriceInCents, tax: taxPercent } = price

  // Full or more than full discount
  if (discountInCents >= withTaxPriceInCents) {
    return {
      ...price,
      pretaxPrice: 0,
      price: 0,
      discount: withTaxPriceInCents,
    }
  }

  // Zero or negative discount
  if (discountInCents <= 0) {
    log(id, "A zero or negative discount encountered")
    return price
  }

  // Partial discounts, discount is given from the price including the tax
  const newPriceInCents = withTaxPriceInCents - discountInCents
  const newPretaxPriceInCents = round(
    newPriceInCents / (1 + taxPercent / 100),
    0
  )

  return {
    ...price,
    pretaxPrice: newPretaxPriceInCents,
    price: newPriceInCents,
    discount: withTaxPriceInCents - newPriceInCents,
  }
}

/**
 * Get amount left for gift cards.
 */
const getGiftCardUsesLeft = (discount: DiscountCode): number | null => {
  const isGiftCard = discount.description?.includes(
    "Programmatically sold gift card"
  )
  const amountLeft = discount.amount
    ? discount.amount - (discount.amount_used || 0)
    : null

  return isGiftCard ? amountLeft : null
}

export { isValidDiscount, applyDiscount, round, getGiftCardUsesLeft }
